<template>
  <div id="trending">
    <b-container class="mt-3">
      <b-row>
        <b-col>
          <h2>热门游戏</h2>
          <div>
            <PopularPlayItem
              v-for="(game_info, idx) in popular_game_infos"
              :key="idx"
              :game_info="game_info"
            />
          </div>
        </b-col>
        <b-col>
          <h2>热门下载</h2>
          <div>
            <PopularDownloadItem
              v-for="(game_info, idx) in popular_download_infos"
              :key="idx"
              :game_info="game_info"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { trending_max_size } from "@/utils/constants";
import { trending_games, trending_downloads } from "@/utils/info/trending_info";

import PopularPlayItem from "./components/PopularPlayItem.vue";
import PopularDownloadItem from "./components/PopularDownloadItem.vue";

export default {
  name: "trending",
  metaInfo: {
    title: "热门",
    meta: [
      { name: "description", content: "在线 DOS 游戏的热门游戏和热门下载。" },
      { name: "keywords", content: "在线, DOS, 游戏, 在线DOS游戏" },
      { property: "og:title", content: "在线 DOS 游戏的热门游戏和热门下载" },
      { property: "og:url", content: "https://dos.zczc.cz/trending" },
      {
        property: "og:description",
        content: "在线 DOS 游戏的热门游戏和热门下载。",
      },
    ],
    link: [{ rel: "canonical", href: "https://dos.zczc.cz/trending" }],
  },
  computed: {
    popular_game_infos: () => trending_games.slice(0, trending_max_size),
    popular_download_infos: () =>
      trending_downloads.slice(0, trending_max_size),
  },
  components: {
    PopularDownloadItem,
    PopularPlayItem,
  },
};
</script>
