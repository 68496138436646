import { trendingInfos } from "chinese-dos-games-data-export/lib/trending";

for (let gameInfo of trendingInfos.trending_games) {
  if ("coverImage" in gameInfo) {
    gameInfo.coverFilename = gameInfo.coverImage.filename;
    gameInfo.width = gameInfo.coverImage.width;
    gameInfo.height = gameInfo.coverImage.height;
  }
}

for (let gameInfo of trendingInfos.trending_downloads) {
  if ("coverImage" in gameInfo) {
    gameInfo.coverFilename = gameInfo.coverImage.filename;
    gameInfo.width = gameInfo.coverImage.width;
    gameInfo.height = gameInfo.coverImage.height;
  }
}

export const trending_games = trendingInfos.trending_games;
export const trending_downloads = trendingInfos.trending_downloads;
