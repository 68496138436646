<template>
  <b-card class="mb-3" :key="identifier" no-body>
    <b-row class="no-gutters">
      <b-col md="2" v-if="cover_url" class="trending-img">
        <router-link
          :to="{
            name: 'download',
            params: { identifier: identifier },
          }"
        >
          <b-card-img
            :src="cover_url"
            :alt="cover_alt"
            :srcset="cover_srcset_array"
            sizes="50vw"
          />
        </router-link>
      </b-col>
      <b-col md="10">
        <b-card-body :title="name" title-tag="h5">
          <b-card-text>
            <small class="text-muted">
              <router-link
                :to="{
                  name: 'download',
                  params: { identifier: identifier },
                }"
                class="card-link"
              >
                进行下载</router-link
              >
            </small>
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import game_info_mixin from "@/mixins/game_info_mixin";

export default {
  name: "PopularDownloadItem",
  props: ["game_info"],
  mixins: [game_info_mixin],
};
</script>

<style scoped>
.trending-img {
  display: flex;
  align-items: center;
}
</style>